
@use '@angular/material' as mat;

$diva-cloud-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      use-system-variables: true,
    ),
    typography: (
      use-system-variables: true,
    )
  )
);

@include mat.core();

html {
  @include mat.all-component-themes($diva-cloud-theme);
  @include mat.system-level-colors($diva-cloud-theme);
  @include mat.system-level-typography($diva-cloud-theme);
}

:root {
  --sys-body-large-font: "Open Sans", "Noto Sans JP";
  --sys-body-large-line-height: normal;
  --sys-body-large-size: 14px;
  --sys-body-large-tracking: normal;
  --sys-label-large-font: "Open Sans", "Noto Sans JP";
  --sys-label-large-line-height: normal;
  --sys-label-large-size: 14px;
  --sys-label-large-weight: 400;
  --sys-label-large-tracking: normal;
  --sys-headline-small-font: "Open Sans", "Noto Sans JP";
  --sys-headline-small-line-height: 32px;
  --sys-headline-small-weight: 500;
  --sys-headline-small-tracking: normal;
  --sys-outline: #0000001f;
  --sys-on-surface: #000000de;
  --sys-surface: #ffffff;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-horizontal-padding: 16px;  
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-padding: 8px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --sys-body-medium-font: "Open Sans", "Noto Sans JP";
  --sys-body-medium-line-height: normal;
  --sys-body-medium-tracking: normal;
  --mdc-dialog-subhead-tracking: .0125em;
  --mat-select-panel-background-color: white;
}