$themes: (
    diva-cloud-light: (
        diva-blue: #379be5,
        diva-light-blue: #b9dcf7,
        diva-green: #00acc1,
        diva-indigo-blue: #5c6bc0,
        diva-orange: #eb672c,
        diva-pink: #e658a1,
        diva-violet: #ab47bc,
        diva-yellow: #fdd835,
        diva-lime-green: #5cc100,
        diva-error-red: #d0021b,
        diva-light-green: #d0f3f7,
        diva-light-indigo-blue: #cce5f4,
        diva-light-orange: #ffe7dd,
        diva-light-pink: #ffe3f1,
        diva-light-violet: #f6d4fc,
        diva-light-yellow: #fff8db,
        diva-light-lime-green: #defbc3,
        diva-medium-gray: #d8d8d8,
        diva-dark-gray: #cccccc,
        diva-navy: #443f5f,
        diva-dark-blue: #205a85,
        diva-black: #141720,
        diva-light-black: #2d313d,
        diva-bg-light-blue: #e2eff9,
        diva-light-gray: #f5f5f5,
        diva-white: #ffffff,
    ),
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
        :host-context(.theme-#{$theme}) & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@mixin themifyNoHostContext($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

@mixin regular-button {
  height: 40px;
  line-height: 1;
  font-weight: 700;

  @include themify($themes) {
    background-color: themed("diva-blue");
    color: themed("diva-white");
  }

  @include themifyNoHostContext($themes) {
    background-color: themed("diva-blue");
    color: themed("diva-white");
  }
}

@mixin regular-button-rounded {
  height: 40px;
  line-height: 1;
  font-weight: 600;
  border-radius: 30px;

  @include themify($themes) {
    background-color: themed("diva-blue");
    color: themed("diva-white");
  }
}

@mixin cancel-button {
  height: 40px;
  line-height: 1;
  font-weight: 700;

  @include themifyNoHostContext($themes) {
    border-color: themed("diva-navy");
    color: themed("diva-navy");
  }
}

@mixin mat-icon-overflow {

  @include themifyNoHostContext($themes) {
    overflow: visible;
  }
}
