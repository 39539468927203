@use 'diva-cloud-theme' as diva;

/* You add global styles to this file, and also import other style files */

//Set default font family
* {
  font-family: "Open Sans", "Noto Sans JP";
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25rem;
}

html {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  margin: 0;
  font-size: 16px !important;
  margin-bottom: 16px;
}

.mat-drawer-content {
  margin-left: 80px;
}

.mat-drawer-opened {
  + .mat-drawer-content {
    margin-left: 290px;
  }
}

.app-confirm-dialog .mat-mdc-dialog-actions {
  .mat-mdc-outlined-button {
    @include diva.cancel-button;
  }

  .mat-mdc-unelevated-button {
    @include diva.regular-button;
  }
}

/*Custom css for components generated*/
.myPanelClass {
  margin: 25px 0px;
}

.mat-icon {
  @include diva.mat-icon-overflow;
}

[app-disabled] {
  pointer-events: none;
}
